/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { SxProps, Theme } from '@mui/material';

export const carouselProviderSX: SxProps<Theme> = (theme) => ({
	position: 'relative',
	py: 0,
	px: 0,
	// mt: 3,
	// height: '320px',
	[theme.breakpoints.down(410)]: {
		'.carousel__slider.carousel__slider--horizontal': {
			height: 'inherit',
			'> .carousel__slider-tray-wrapper.carousel__slider-tray-wrap--horizontal': {
				height: 'inherit',
				'> .carousel__slider-tray.carousel__slider-tray--horizontal': {
					height: 'inherit',
					'> .carousel__slide': {
						height: 'inherit',
					},
				},
			},
		},
	},

	'.carousel__back-button, .carousel__next-button': {
		position: 'absolute',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		top: '50%',
		transform: 'translateY(-50%)',
		height: (theme) => theme.spacing(5),
		width: (theme) => theme.spacing(5),
		borderRadius: '50%',
		background: 'none',
		border: '1px solid transparent',
		color: 'base.contrastText',
		'&:after': {
			content: '""',
			display: 'block',
			position: 'absolute',
			zIndex: '-1',
			width: '75%',
			height: '75%',
			borderRadius: '50%',
			background: 'rgba(0, 0, 0, .4)',
		},
		'&:hover': {
			cursor: 'pointer',
			color: 'base.main',
			'&:after': {
				background: 'rgba(255, 255, 255, .5)',
			},
		},
	},

	'.carousel__back-button': {
		left: 0,
	},

	'.carousel__next-button': {
		right: 0,
	},

	'.carousel__slider': {
		overflow: 'hidden',
	},

	'.carousel__dot': {
		xs: {
			width: '15px',
			height: '15px',
			borderRadius: '100%',
			margin: '5px',
			border: 'none',
			backgroundColor: theme.palette.background.main,
			opacity: 0.5,
			'&:disabled': {
				opacity: 1,
			},
		},
		md: { backgroundColor: 'white' },
	},

	div: {
		outline: 'none',
	},
});

export const carouselDotGroupSX = (position?: string): SxProps => ({
	...(position !== 'left' && { paddingTop: '10px' }),
	'.carousel__dot-group': {
		'.carousel__dot': { backgroundColor: '#333' },
		xs: { display: 'flex', justifyContent: 'center' },
		md: { position: 'relative', bottom: '5px', left: '0' },
	},

	...(position === 'left' && {
		'.carousel__dot-group': {
			xs: { display: 'flex', justifyContent: 'center' },
			md: { position: 'absolute', bottom: '25px', left: '25px' },
		},
	}),
});
